<template>
  <div class="frame-player">

    <frame-player ref="theFramePlayer" />

  </div>
</template>

<script>
import FramePlayer from 'vue-frame-player'
export default {
  components: {
    FramePlayer
  },

  data () {
    return {}
  },

  computed: {},

  watch: {},

  methods: {},

  created () { },

  mounted () {
    const player = this.$refs.theFramePlayer
    player.init({
      imageMode: 'visible',
      playMode: 'loop',
      length: 49,
      initialImages: (i, length) =>
        require(`../../assets/images/downloadQRCode/xunhu.png`),
      //   initialImages:
      //     'https://vmllab-js.github.io/vFramePlayer/image/[frame].jpg',
      playStep: 1,
      playSpeed: 1,
      autoplay: true,
      preload: true
    })
  }
}
</script>
<style lang='less' scoped>
// @import url(); 引入公共css类
.frame-player {
  width: 100%;
  height: 100%;
}
</style>
